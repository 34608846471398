import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import '@/assets/scss/element-variables.scss'	//自定义默认样式
import '@/assets/scss/reset.scss'				//自定义默认样式
import extend from './extend.js'				//扩展的全局方法
import './assets/icons';						 // icon
import 'element-ui/lib/theme-chalk/icon.css'

Vue.use(ElementUI);
Vue.use(extend);
Vue.config.productionTip = false;

//是否登录？路由拦截
router.beforeEach((to, from, next) => {
	if (to.matched.some(route =>  route.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (localStorage.getItem("token")==null) {
			next({path: '/login'});
		} else {
			next()
		}
	} else {
		next() // 确保一定要调用 next()
	}
});
		

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
