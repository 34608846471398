import Vue from "vue";
import Vuex from "vuex";
import nav from './nav.js'
import header from './header.js'
import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: localStorage.getItem("token") || '',		//成功登录之后服务器返回的身份标识字符串，jwt格式。
		showLoading:false,								//自定义的全局 loading组件，所使用的全局变量，这个Loading组件嵌套在Layout布局组件中
		roles:[],										//当前用户拥有的角色，除此之外在nav中还有一个当前用户的permission，主要搭配 v-if判断页面元素是否应该显示
		i18n:"en"										//合法值 zh,en,默认zh

		
		
		
	},
	mutations: {
	
		setToken: function(state, token) {
			 axios.defaults.headers.common['token'] = token;
			 localStorage.setItem("token",token);
			 state.token = token;
		},
		clearToken: function(state) {
			axios.defaults.headers.common['token'] = '';
			localStorage.removeItem("token");
			state.token = '';
			console.log("clearToken")
		},
		showLoading(state){
			state.showLoading=true;
		},
		hideLoading(state){
			state.showLoading=false;
		},
		setOwnRoles(state,value){
			state.roles=value;
		},
		clearRoles(state) {
			// console.log("clearRoles")
			// state.roles.splice(0,state.roles.length);
			state.roles=[];
		}
	},
	actions: {},
	modules: {
		nav,
		header
	}
});
