import Vue from "vue";
import VueRouter from "vue-router";
import {prefix} from '../api/base.js'

Vue.use(VueRouter);

const routes = [
	{
	  path:"/",
	  component:()=> import("../components/Layout.vue"),
	  meta:{requiresAuth:true},
	  children:[
		{
			  path:"/",
			  name:"home",
			  component:()=> import("../views/home/Home.vue"),
			  meta:{requiresAuth:true,title:"首页"}
		},
		{
			  path:"/pages/user/changePassword.html",
			  name:"changePassword",
			  component:()=> import("../views/admin/ChangePassword.vue"),
			  meta:{requiresAuth:true,title:"修改密码"}
		},
		{
			  path:"/pages/user/userList.html",
			  name:"userList",
			  component:()=> import("../views/user/UserList.vue"),
			  meta:{requiresAuth:true,title:"用户列表"}
		},
		{
			  path:"/addUser",
			  name:"addUser",
			  component:()=> import("../views/user/AddUser.vue"),
			  meta:{requiresAuth:true,title:"添加用户"}
		},
		{
			  path:"/updateUser",
			  name:"updateUser",
			  component:()=> import("../views/user/UpdateUser.vue"),
			  meta:{requiresAuth:true,title:"更新用户"}
		},
		{
			  path:"/updateHeadImg",
			  name:"updateHeadImg",
			  component:()=> import("../views/user/UpdateHeadImg.vue"),
			  meta:{requiresAuth:true,title:"更新头像"}
		},
		{
			  path:"/updateMySelf",
			  name:"updateMySelf",
			  component:()=> import("../views/user/UpdateMySelf.vue"),
			  meta:{requiresAuth:true,title:"个人信息"}
		},
		{
			  path:"/pages/menu/menuList.html",
			  name:"menuList",
			  component:()=> import("../views/menu/MenuList.vue"),
			  meta:{requiresAuth:true,title:"菜单列表"}
		},
		{
			  path:"/addMenu",
			  name:"addMenu",
			  component:()=> import("../views/menu/AddMenu.vue"),
			  meta:{requiresAuth:true,title:"添加菜单"}
		},
		{
			  path:"/updateMenu",
			  name:"updateMenu",
			  component:()=> import("../views/menu/UpdateMenu.vue"),
			  meta:{requiresAuth:true,title:"更新菜单"}
		},
		{
			  path:"/pages/role/roleList.html",
			  name:"roleList",
			  component:()=> import("../views/role/RoleList.vue"),
			  meta:{requiresAuth:true,title:"角色列表"}
		},
		{
			  path:"/addRole",
			  name:"addRole",
			  component:()=> import("../views/role/AddRole.vue"),
			  meta:{requiresAuth:true,title:"添加角色"}
		},
		{
			  path:"/updateRole",
			  name:"updateRole",
			  component:()=> import("../views/role/UpdateRole.vue"),
			  meta:{requiresAuth:true,title:"更新角色"}
		},
		{
			  path:"/pages/file/fileList.html",
			  name:"fileList",
			  component:()=> import("../views/file/FileList.vue"),
			  meta:{requiresAuth:true,title:"文件列表"}
		},
		{
			  path:"/druid/index.html",
			  name:"druid",
			  component:()=> import("../views/DataSource.vue"),
			  meta:{requiresAuth:true,title:"druid"}
		},
		{
			  path:"/swagger-ui.html",
			  name:"swagger",
			  component:()=> import("../views/Swagger.vue"),
			  meta:{requiresAuth:true,title:"swagger"}
		},
		{
			  path:"/pages/generate/edit.html",
			  name:"generate",
			  component:()=> import("../views/Generate.vue"),
			  meta:{requiresAuth:true,title:"生成代码"}
		},
		{
			  path:"/pages/notice/noticeList.html",
			  name:"noticeList",
			  component:()=> import("../views/notice/NoticeList.vue"),
			  meta:{requiresAuth:true,title:"公告列表"}
		},
		{
			  path:"/addNotice",
			  name:"addNotice",
			  component:()=> import("../views/notice/AddNotice.vue"),
			  meta:{requiresAuth:true,title:"添加公告"}
		},
		{
			  path:"/updateNotice",
			  name:"updateNotice",
			  component:()=> import("../views/notice/UpdateNotice.vue"),
			  meta:{requiresAuth:true,title:"更新公告"}
		},
		{
			  path:"/pages/notice/noticePubList.html",
			  name:"noticePubList",
			  component:()=> import("../views/notice/NoticePubList.vue"),
			  meta:{requiresAuth:true,title:"未读公告"}
		},
		{
			  path:"/pages/log/logList.html",
			  name:"logList",
			  component:()=> import("../views/LogList.vue"),
			  meta:{requiresAuth:true,title:"日志列表"}
		},
		{
			  path:"/pages/mail/mailList.html",
			  name:"mailList",
			  component:()=> import("../views/mail/MailList.vue"),
			  meta:{requiresAuth:true,title:"邮件列表"}
		},
		{
			  path:"/addMail",
			  name:"addMail",
			  component:()=> import("../views/mail/AddMail.vue"),
			  meta:{requiresAuth:true,title:"添加邮件"}
		},
		{
			  path:"/pages/job/jobList.html",
			  name:"jobList",
			  component:()=> import("../views/job/JobList.vue"),
			  meta:{requiresAuth:true,title:"定时任务列表"}
		},
		{
			  path:"/addJob",
			  name:"addJob",
			  component:()=> import("../views/job/AddJob.vue"),
			  meta:{requiresAuth:true,title:"添加定时任务"}
		},
		{
			  path:"/updateJob",
			  name:"updateJob",
			  component:()=> import("../views/job/UpdateJob.vue"),
			  meta:{requiresAuth:true,title:"更新定时任务"}
		},
		{
			  path:"/pages/excel/sql.html",
			  name:"exportSql",
			  component:()=> import("../views/ExportSql.vue"),
			  meta:{requiresAuth:true,title:"sql数据导出"}
		},
		{
			  path:"/pages/dict/dictList.html",
			  name:"dictList",
			  component:()=> import("../views/dict/DictList.vue"),
			  meta:{requiresAuth:true,title:"常量词列表"}
		},
		{
			  path:"/addDict",
			  name:"addDict",
			  component:()=> import("../views/dict/AddDict.vue"),
			  meta:{requiresAuth:true,title:"添加常量词"}
		},
		{
			  path:"/updateDict",
			  name:"updateDict",
			  component:()=> import("../views/dict/UpdateDict.vue"),
			  meta:{requiresAuth:true,title:"更新常量词"}
		},
		{
			  path:"/system/deptList",
			  name:"dept",
			  component:()=> import("../views/dept"),
			  meta:{requiresAuth:true,title:"部门"}
		},
		{
			  path:"/system/post",
			  name:"post",
			  component:()=> import("../views/post"),
			  meta:{requiresAuth:true,title:"岗位"}
		},
		{
			  path:"/other",
			  name:"other",
			  component:()=> import("../views/Other.vue"),
			  meta:{requiresAuth:true,title:"其它"}
		},
		{
			  path:"/dashboard",
			  name:"Dashboard",
			  component:()=> import("../views/Dashboard.vue"),
			  meta:{requiresAuth:true,title:"仪表盘"}
		},
		{
			  path:"/order",
			  name:"Order",
			  component:()=> import("../views/order/OrderList.vue"),
			  meta:{requiresAuth:true,title:"Order"}
		},
		{
			  path:"/addOrder",
			  name:"EditOrder",
			  component:()=> import("../views/order/EditOrder.vue"),
			  meta:{requiresAuth:true,title:"EditOrder"}
		},
		{
			  path:"/order/log",
			  name:"OrderLog",
			  component:()=> import("../views/order/OrderLogList.vue"),
			  meta:{requiresAuth:true,title:"OrderLog"}
		},
		{
			  path:"/calendar",
			  name:"Calendar",
			  component:()=> import("../views/Calendar.vue"),
			  meta:{requiresAuth:true,title:"日历"}
		},
		{
			  path:"/dispatch",
			  name:"Dispatch",
			  component:()=> import("../views/Dispatch.vue"),
			  meta:{requiresAuth:true,title:"调度"}
		},
		{
			  path:"/marketing",
			  name:"Marketing",
			  component:()=> import("../views/Marketing.vue"),
			  meta:{requiresAuth:true,title:"营销"}
		},
		{
			  path:"/report",
			  name:"Report",
			  component:()=> import("../views/Report.vue"),
			  meta:{requiresAuth:true,title:"Report"}
		},
		{
			  path:"/print",
			  name:"Print",
			  component:()=> import("../views/Print.vue"),
			  meta:{requiresAuth:true,title:"打印"}
		},
		{
			  path:"/operation",
			  name:"Operaation",
			  component:()=> import("../views/Operation.vue"),
			  meta:{requiresAuth:true,title:"运营"}
		},
		{
			  path:"/organization",
			  name:"Organization",
			  component:()=> import("../views/organization/OrganizationList.vue"),
			  meta:{requiresAuth:true,title:"Organization"}
		},
		{
			  path:"/customer",
			  name:"Customer",
			  component:()=> import("../views/CustomerList.vue"),
			  meta:{requiresAuth:true,title:"Customer"}
		},
		{
			  path:"/service/main",
			  name:"MainService",
			  component:()=> import("../views/MainServiceList.vue"),
			  meta:{requiresAuth:true,title:"Service"}
		},
		{
			  path:"/service/sub",
			  name:"SubService",
			  component:()=> import("../views/SubServiceList.vue"),
			  meta:{requiresAuth:true,title:"SubService"}
		},
		{
			  path:"/address",
			  name:"Address",
			  component:()=> import("../views/address/AddressList.vue"),
			  meta:{requiresAuth:true,title:"Address"}
		},
		{
			  path:"/material",
			  name:"Material",
			  component:()=> import("../views/MaterialList.vue"),
			  meta:{requiresAuth:true,title:"Material"}
		},
		{
			  path:"/sms",
			  name:"Sms",
			  component:()=> import("../views/SmsList.vue"),
			  meta:{requiresAuth:true,title:"Sms"}
		},
		{
			  path:"/call/log",
			  name:"CallLog",
			  component:()=> import("../views/CallLogList.vue"),
			  meta:{requiresAuth:true,title:"CallLog"}
		}
		
	  ]
	  
	},
	{path:"/login",name:"login",component:()=> import("../views/Login.vue")},
	{path: "*",name:"404",component:()=>import("../views/404.vue")},

];

const router = new VueRouter({
  routes:routes,
  base:prefix
});

export default router;
