export default {
	state: {
		isCollapse: false, //控制侧边栏折叠展开的变量
		tabList: [],
		menuList: [],
		permissions: [],
		keepAlive:[],
		targetTab:{},
		backlogCount:0,	//待办任务数量
		refreshBacklog:0		//通知BacklogOfUser组件刷新任务待办
	},
	mutations: {
		collapseMenu(state) {
			state.isCollapse = !state.isCollapse;
		},
	
		addTab(state, route) {
			if(route.path == "/"){return;}		//不添加“主页”tab标签
 			var pathIndex = state.tabList.findIndex(item => item.path == route.path);
			if (pathIndex == -1) { 				//tab列表栏，只添加新增的。query不同但path相同，表示组件是被缓存过的。同一组件不同query参数的更新操作需要在组件生命周期方法activated内判断
				state.tabList.push({
					title: route.meta.title,
					path: route.path,
					fullPath: route.fullPath,
					name:route.matched[1].components.default.name
				});
			}else{
				state.tabList[pathIndex].fullPath = route.fullPath;
			}
		},

		closeTab(state, value) {
			var index = state.tabList.findIndex(item => {return item.path == value.path;});
			if (index != -1) {
				state.tabList.splice(index, 1);
			}
		},
		
		setMenuList(state, menuList) {
			state.menuList = menuList;
		},
		// 废弃，改为 sessionStorage.setItem()存储，
		setPermissions(state, permissions) {
			state.permissions = permissions;
		},
		setBacklogCount(state, value) {
			state.backlogCount=value;
		},
		refreshBacklog(state){
			state.refreshBacklog=Date.now();
		},
		clearPermissions: function(state) {
			state.permissions=[];
			console.log("clearPermissions")
			// state.permissions.splice(0,state.permissions.length);
		}
	
	},
	actions: {},
	modules: {},
};
