export default 
{
	//example
	child:{
		boy:{
			username:"fei"
		},
		girl:{
			username:"yang"
		}
	},
	
	
	
	systemName:"Order Platform",
	login:"log in",
	orgName:"Organization"
	
}

