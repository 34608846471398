export default 
{
	//示例
	child:{
		boy:{
			username:"飞"
		},
		girl:{
			username:"羊"
		}
	},
	
	
	
	systemName:"接单平台",
	login:"登录",
	orgName:"公司"
	
	
	
}

