export default {
	state: {
		headImgUrl:  require("../assets/images/user-default.png")
	},
	mutations: {
		updateHeadImg(state,value) {
			state.headImgUrl = value;
		}

	},
	actions: {},
	modules: {},
};
